.layout{
    margin-left: 22rem;
    margin-right: 22rem;
}
.main-mockup{
    width:60rem;
}
.top-bg{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 100%; */
    /* height: 100%; */
    justify-content: center;
}

.subtitle{
    font-family: 'AppleSDGothicNeoEB';
    color: #B1B1B1;
    font-size: 2.4rem;
    margin-bottom: 3rem;
    padding-top: 1rem;
}
.center-circle{
    font-family: 'AppleSDGothicNeoSB';
    background-color: white;
    border-radius: 50%;
    text-align: center;
    vertical-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 15rem;
    height: 15rem;
    font-size: 0.9rem;
    border: 2px solid;
}

.circle{
    font-family: 'AppleSDGothicNeoSB';
    background-color: white;
    border-radius: 50%;
    text-align: center;
    vertical-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 13rem;
    height: 13rem;
    font-size: 1.4rem;
    border: 2px dashed;
}

.circle:not(:first-child){
    margin-left: -1rem;
}

.result-circle{
    color: white;
    border-radius: 50%;
    text-align: center;
    vertical-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 13rem;
    height: 13rem;
    font-size: 0.7rem;
    
}
.app-mockup{
    width: 30rem;
}
.mockup-img{
    width: 14rem;
    height: auto;
}
.mockup-img:not(:first-child){
    margin-left: -5rem;
}

.persona{
    position: relative;
    top: -3rem;
}
.back-mockup{
    top: 0rem;
    right: -5rem;
    width: 60rem;
    z-index: 1;
}
.hash-tag{
    border: solid 2px;
    padding: 0.5rem 1.3rem;
    border-radius: 50rem;
    text-align: center;
    font-size: 1.3rem;
    
}
/* .hash-tag:hover{
    cursor: pointer;
} */

.hash-tag-active{
    border: solid 2px;
    padding: 0.5rem 1.3rem;
    border-radius: 50rem;
    text-align: center;
    font-size: 1.3rem;
}
.title-hover{
    color: #0060FC;
}
.service-sb-txt{
    font-size: 3rem;
}
.service-eb-txt{
    font-size: 3rem;
}
.mock-img{
    /* width: 25rem; */
}

.dt-title{
    font-size: 2rem;
    font-family:'AppleSDGothicNeoB00' ;
}
.dt-sub{
    font-size: 1.2rem;
}
.web-page-img{
    width: 50rem;
}
.point-circle[data-isOpen="false"]{
    width: 2rem ;
    height: 2rem ;
    border-radius: 50%;
    background-color: white;
    position: absolute;
}
.point-circle[data-isOpen="true"] {
    width: 20rem;
    height: 10rem;
    border-radius: 0.4rem !important;
    position: absolute ;
    padding:1rem;
    border-width: 2px !important;
    background-color: white;
  }
  .arrow{
    visibility:visible;
  }

  .myweather-icon{
    width:6rem;
    height: 6rem;
  }
  @media screen and (max-width: 368px) {
  .subtitle{
    font-size: 1.8rem;
    margin-bottom: 3rem;
    padding-top: 1rem;
}
  }
@media screen and (max-width: 768px) {
	.layout{
        
        margin-left: 2rem;
        margin-right: 2rem;
        display: block;
    }
    .arrow{
        visibility: hidden;
      }

    .dt-title{
        font-size: 1.6rem;
        font-family:'AppleSDGothicNeoB00' ;
    }
    .dt-sub{
        font-size: 1.3rem;
    }
    .web-page-img{
        width: 30rem;
    }
    
    .service-sb-txt{
        font-size: 1.5rem;
    }
    .service-eb-txt{
        font-size: 1.5rem;
    }
    .app-mockup{
        width: 25rem;
    }
    .subtitle{
        margin-bottom: 1rem;
    }
    .circle{
        width: 15rem;
        height: 15rem;
        font-size: 1.5rem;
    }
    .circle:not(:first-child){
    margin-left: 0rem;
    margin-top: -0.5rem;
}

    .result-circle{
        width: 18rem;
        height: 18rem;
        font-size: 1.6rem;
    }
    
}
