/* .inner{
  display: flex;
  flex-direction: column; */
  /* height: 100%; */
  /* margin-top: 3.8rem; */
  /* margin-bottom: 10px; */
  
/* } */

.top-container{
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid black;
}
.logo{
  font-size: 4rem;
  font-weight: bold;
  border-right: 2px solid black;
  margin-right: 1rem;
  flex:1;
}
.footer{
  position: fixed;
  bottom: 10px;
  left:50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
}

.top-menu{
  flex:0.5;
}
.keyword{
  font-family: 'FaktumTestBold';
  font-size: 4rem;
  border: 1px solid black;
  border-radius: 4rem;
  text-align: center;
  padding:0.2rem 2rem;
  margin-left: 1rem;
}


.page {
  position: absolute;
}

.page-enter {
  opacity: 0;
  transform: scale(1.3);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


@media screen and (max-width: 768px) {
  .footer{
    position: fixed;
    bottom: 5px;
    left:50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
  }
  
}
