.home-container{
  cursor: none;
}
.ch-bg:hover{
    box-sizing: border-box;
    color: white !important;
    background-color: #0060FC;
    transition: all ease 2s 0s;
}


.diagonal {
  left:2%;
  width: 95vw;
  height: 80vh;
  margin-top: 9rem;
  position: relative;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="0" x2="100%" y2="100%" stroke="black" /></svg>');
}
.scroll-box {
  display: flex;
}
.scroll-box.horizon {
  flex-direction: row;
  overflow: auto hidden;
  scroll-snap-type: x mandatory;
}
.scroll-box.vertical {
  flex-direction: column;
  overflow: hidden auto;
  scroll-snap-type: y mandatory;
}
.item {
  scroll-snap-align: start;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* text-align: center; */
}

.fullpage{
    height: calc(100vh - 5rem);
    margin-top: 5rem;
  }
  
.text-stroke{
    /* background-color: white; */
    color: transparent;
    -webkit-text-stroke: 1px black;
}
.sticky-elem{
    display: none;
    position: fifxed;
    /* top:0; */
    left:0;
    width: 100%;
}
.sticky-elem-canvas{
    top:0;
    height: 100%;
}

.sticky-elem-canvas canvas{
    position: absolute;
    top:50%;
    left:50%;
    
}


.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: #0060FC;
  bottom: 0px;
}

.animate-slider-left {
    position:absolute; 
    top:0px; 
    left:0px; 
    overflow:hidden; 
    white-space: nowrap;
    animation: moveleft 20s linear infinite;
  }
  .animate-slider-right {
    position:absolute; 
    top:0px; 
    left:0px;  
    overflow:hidden; 
    white-space: nowrap;
    animation: moveright 20s linear infinite;
  }

  .comp-spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    /* transform: rotate(3deg); */
     /* transform: rotate(0.3rad);/ */
     /* transform: rotate(3grad); */ 
     /* transform: rotate(.03turn);  */
  }

  .drag-tag{
    
  }


button {
    appearance: none;
    border: none;
    cursor: pointer;
    font-family: 'AppleSDGothicNeoB00';
    /* background-color: #acc7ed; */
    /* color: #fff; */
    /* border:2px solid black;
    border-radius: 60px; */
    outline: none;
    margin: 0;
    padding: 0rem 00rem;
    font-size: 2rem;
    font-size: 1.3rem;
    /* font-weight: 600; */
    /* line-height: 48px; */
    /* letter-spacing: -1px; */
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
  }
  
  .shapes {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 60px;
    background: linear-gradient(
      60deg,
      var(--blue) 0%,
      #d6cbf6 30%,
      var(--pink) 70%
    );
  }
  
  .blush {
    position: absolute;
    bottom: -15px;
    width: 100px;
    height: 30px;
    filter: blur(20px);
  }
  
  .blush.pink {
    right: 20px;
    background: var(--purple);
  }
  
  .blush.blue {
    left: 20px;
    background: var(--blue);
  }
  
  .shapes .container {
    position: absolute;
    top: -100px;
    bottom: -100px;
    left: -100px;
    right: -100px;
    width: calc(100% + 200px);
    pointer-events: none;
  }
  
  .shapes canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .label {
    /* width: 10rem; */
    padding: 20px 0;
    transform: translateZ(0);
    font-weight: 700;
    z-index: 1;
  }
  
  .default {
    display: block;
  }
  
  .number {
    padding: 20px 0;
    width: 88px;
    position: relative;
    transform: translateZ(0);
  }
  
  .number:before {
    content: "";
    position: absolute;
    left: 0;
    top: 1px;
    bottom: 1px;
    width: 1px;
    background-color: #35373f;
  }
  
  .current {
    color: #8a8d9b;
    opacity: 1;
    display: block;
  }
  
  .new {
    color: #fbfaaa;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    display: block;
  }
  
  .add {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    transform: translateY(38px);
    pointer-events: none;
    color: #d0d0db;
    display: block;
  }


.refresh {
  padding: 5px;
  position: absolute;
  border: 1px dotted var(--accent);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.refresh path {
  fill: black;
}

.box {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--accent);
  margin: 0;
}

.menu {
  font-family: 'GmarketSansBold';
  position: fixed;
  top: 0;
  right:0;
  bottom: 0;
  width: 400px;
  background: black;
  padding-top: 100px;
  transform: translateX(100%);
  will-change: transform;
}

.menu-ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  list-style: none;
 white-space: nowrap
}

.menu-li{
  color: white;
  display: block;
  transform-origin: -20px 50%;
  font-weight: bold;
  font-size: 48px;
  padding: 10px;
  will-change: transform, opacity, filter;
}

ul,
li {
  list-style: none;
  margin: 0;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fill-txt{
  /* position: absolute;
  left: 50%;
  top: 50%; */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
 /* font-family: 'FaktumTestBold'; */
 /* color: transparent; */
 color:white;
 -webkit-text-stroke: 1px black;
 text-align: center;
 /* font-size: 3.75rem; */
 margin-top: 2rem;
 margin-bottom: 2rem;
 line-height: 1;
 position: relative;
}

.fill-txt-mask{
  /* background-color: #dcf6cb; */
  font-family: 'FaktumTestBold';
  align-self: center;
  text-align: center;
  font-size: 3.75rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1;

 }

.fill-inner-container {
  position: absolute;
  height: 80px;
  /* background-color: #d6cbf6; */
  /* overflow: hidden;
  outline: 1px transparent; */
}

.contact-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  grid-gap: 25px;
  padding: 25px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
  will-change: width, height;
}

.contact-item {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  will-change: transform, opacity;
}
.content-keyword{
  /* font-size: ; */
  font-size:16rem;
}

@media screen and (max-width: 768px) {
  .content-keyword{
    font-size: 9rem;
  }
  .menu {
    
    width: 100%;

  }
}



  

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes moveleft {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, 0);
    }
  }

  @keyframes moveright {
    0% {
        transform: translate(-50%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
  }



