@tailwind base;
@tailwind components;
@tailwind utilities;
:root{
	--purple: #db07d1;
    --pink: #f2056f;
    --blue: #61dafb;
	--grey: #5E5D5E;
	--mid-grey: #3f3f3f;
}
@font-face {
	font-family: "GmarketSansBold";
	src: url("../asset/font/GmarketSansBold.otf") format("opentype");
}
@font-face {
	font-family: "GmarketSansLight";
	src: url("../asset/font/GmarketSansLight.otf") format("opentype");
}
@font-face {
	font-family: "GmarketSansMedium";
	src: url("../asset/font/GmarketSansMedium.otf") format("opentype");
}

@font-face {
	font-family: "Morena-Bold";
	src: url("../asset/font/Morena-Bold.otf") format("opentype");
}

@font-face {
	font-family: "AppleSDGothicNeoB00";
	src: url("../asset/font/AppleSDGothicNeoB00.ttf") 
}

@font-face {
	font-family: "AppleSDGothicNeoM00";
	src: url("../asset/font/AppleSDGothicNeoM00.ttf") 
}

@font-face {
	font-family: "AppleSDGothicNeoEB";
	src: url("../asset/font/AppleSDGothicNeoEB.ttf") 
}

@font-face {
	font-family: "AppleSDGothicNeoSB";
	src: url("../asset/font/AppleSDGothicNeoSB.ttf") 
}

@font-face {
	font-family: "FaktumTestBold";
	src: url("../asset/font/FaktumTestBold.otf") format("opentype");
}

@font-face {
	font-family: "FaktumTestRegular";
	src: url("../asset/font/FaktumTestRegular.otf") format("opentype");
}

* {
	-ms-overflow-style: none;
	-webkit-font-smoothing: antialiased;
	/* cursor: none; */
}
::-webkit-scrollbar {
    display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s;
	/* -webkit-text-fill-color: #fff !important; */
}

input:-webkit-autofill {
	-webkit-animation-delay: 1s;
	/* Safari support - any positive time runs instantly */
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
	
}

/* ::-webkit-scrollbar {
    display: none;
} */
input:focus {
	outline: none;
}

html {
	height: 100%;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	/* -moz-osx-font-smoothing: grayscale; */
	/* font-size: 18px; */
	font-size: 62.5%;
	box-sizing: border-box;
	overflow: scroll;
	
}

body {
	font-size: 1rem;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	overflow: scroll;
	background-color: white;
}


#root {
	/* font-family: "GmarketSansMedium", sans-serif; */
	font-family: "AppleSDGothicNeoM00", sans-serif;
	height: 100%;

}

div {
	margin: 0;
	padding: 0;

}

a {
	color: inherit !important;
	text-decoration: none !important;
}

a:hover {
	opacity: 0.7;
}

li,
ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
}
.max-container{
	display: flex;
	max-width: 1920px;
	flex-direction: column;
}

.cursor-dot,
.cursor-dot-outline{
	pointer-events: none;
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%,-50%);
	border-radius: 50%;
	opacity: 1;
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* .cursor-dot{
	width: 1rem;
	height: 1rem;
	background-color: #0060FC;
}

.cursor-dot-outline{
	width: 40px;
	height: 40px;
	background-color: rgba(0, 96, 252, 0.5);
} */

.cursor {
	position: fixed;
	left: 0;
	top: 0;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	/* border-radius: 16px; */
	/* background-color: black; */
	mix-blend-mode: difference;
	/* background-color: #0060FC; */
	background-color: white;
	z-index: 999999;
  }




.hooksMain > div {
	position: absolute;
	will-change: transform;
	border-radius: 50%;
	background: #0060FC;
	box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
	opacity: 0.6;
  }
  
  .hooksMain > div:nth-child(1) {
	width: 60px;
	height: 60px;
  }
  
  .hooksMain > div:nth-child(2) {
	width: 125px;
	height: 125px;
  }
  
  .hooksMain > div:nth-child(3) {
	width: 75px;
	height: 75px;
  }
  
  .hooksMain > div::after {
	content: '';
	position: absolute;
	top: 20px;
	left: 20px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.8);
  }
  
  .hooksMain > div:nth-child(2)::after {
	top: 35px;
	left: 35px;
	width: 35px;
	height: 35px;
  }
  
  .hooksMain > div:nth-child(3)::after {
	top: 25px;
	left: 25px;
	width: 25px;
	height: 25px;
  }
  
  .hooksMain {
	position: absolute;
	width: 100%;
	height: 100%;
	filter: url('#goo');
	overflow: hidden;
	background: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
  }
  